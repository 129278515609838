import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_picker = _resolveComponent("header-picker")!
  const _component_calendar = _resolveComponent("calendar")!
  const _component_footer_picker = _resolveComponent("footer-picker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pickers-container maz-elevation", [_ctx.position, { 'inline': _ctx.inline }]]),
    tabindex: "-1"
  }, [
    (_ctx.hasHeader)
      ? (_openBlock(), _createBlock(_component_header_picker, {
          key: 0,
          "model-value": _ctx.dateMoment,
          locale: _ctx.locale,
          "has-time": _ctx.hasTime,
          "has-date": _ctx.hasDate,
          format: _ctx.format,
          color: _ctx.color
        }, null, 8, ["model-value", "locale", "has-time", "has-date", "format", "color"]))
      : _createCommentVNode("", true),
    _createVNode(_component_calendar, {
      modelValue: _ctx.dateMoment,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateMoment) = $event)),
      format: _ctx.format,
      locale: _ctx.locale,
      color: _ctx.color,
      shortcut: _ctx.shortcut,
      "min-date": _ctx.minDate,
      "max-date": _ctx.maxDate,
      "minute-interval": _ctx.minuteInterval,
      "no-weekends-days": _ctx.noWeekendsDays,
      "disabled-dates": _ctx.disabledDates,
      "disabled-weekly": _ctx.disabledWeekly,
      "is-visible": _ctx.isVisible,
      "has-double": _ctx.hasDouble,
      shortcuts: _ctx.shortcuts,
      "has-keyboard": _ctx.hasKeyboard,
      "has-time": _ctx.hasTime,
      "has-date": _ctx.hasDate,
      "has-shortcuts": _ctx.hasShortcuts,
      "disabled-hours": _ctx.disabledHours
    }, null, 8, ["modelValue", "format", "locale", "color", "shortcut", "min-date", "max-date", "minute-interval", "no-weekends-days", "disabled-dates", "disabled-weekly", "is-visible", "has-double", "shortcuts", "has-keyboard", "has-time", "has-date", "has-shortcuts", "disabled-hours"]),
    (_ctx.hasFooter)
      ? (_openBlock(), _createBlock(_component_footer_picker, {
          key: 1,
          color: _ctx.color,
          "model-value": _ctx.dateMoment,
          "has-validate": _ctx.hasValidate,
          "has-now": _ctx.hasNow,
          "now-translation": _ctx.nowTranslation
        }, null, 8, ["color", "model-value", "has-validate", "has-now", "now-translation"]))
      : _createCommentVNode("", true)
  ], 2))
}