<template>
  <div
    :id="`timePicker-${uniqueId}`"
    ref="timePickerRef"
    :style="[{height: `${hasDate ? height : 150}px`}]"
    class="time-picker maz-flex maz-flex-fixed maz-flex-1"
    :class="{
      'maz-border-left maz-border-left-solid maz-border-color': hasDate
    }"
  >
    <div
      v-for="column in columns"
      :key="column.type"
      :id="`column-${column.type}-root`"
      :ref="`${column.type}ref`"
      :class="`time-picker__column-${column.type}`"
      class="time-picker__column maz-flex-1 maz-flex maz-direction-column maz-align-center"
      @scroll="noScrollEvent
        ? null
        : column.type === 'hours' ? onScrollHours($event) : column.type === 'minutes' ? onScrollMinutes($event) : onScrollApms($event)
      "
    >
      <div
        class="before"
        :style="[columnPadding]"
      />
      <MazBtn
        v-for="({ item, disabled, value: v }) in column.items"
        :key="item"
        size="mini"
        tabindex="-1"
        no-shadow
        class="time-picker__column__item maz-flex maz-flex-center maz-flex-fixed maz-bg-transparent maz-text-color maz-p-0"
        :color="color"
        :active="isActive(column.type, v)"
        :disabled="disabled"
        @click="disabled ? null : selectTime(v, column.type)"
      >
        {{ item }}
      </MazBtn>
      <div
        class="after"
        :style="[columnPadding]"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, nextTick, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import {
  ArrayHourRange,
  ArrayMinuteRange,
  debounce,
  getTimeFormat,
  scrollSmoothElement,
  findNearestNumberInList,
  getValue
} from '@web/components/maz/MazPicker/utils'
import moment from 'moment'
import MazBtn from '@web/components/maz/MazBtn'
const ITEM_HEIGHT = 28
export default defineComponent({
  name: 'TimePicker',
  components: { MazBtn },
  props: {
    modelValue: { type: Object, default: Object },
    format: { type: String, default: null },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    minuteInterval: { type: Number, required: true },
    height: { type: Number, required: true },
    hasDate: { type: Boolean, required: true },
    disabledHours: { type: Array, required: true },
    color: { type: String, default: null }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    const uniqueId = uuidv4()
    const timePickerRef = ref<HTMLElement | null>(null)
    const minutesref = ref<HTMLElement | null>(null)
    const hoursref = ref<HTMLElement | null>(null)
    const apmsref = ref<HTMLElement | null>(null)

    const hour = ref(null)
    const minute = ref(null)
    const apm = ref(null)
    const noScrollEvent = ref(false)
    const columnPadding = ref({})

    const dateMoment = computed({
      get () {
        return props.modelValue || moment()
      },
      set (val) {
        ctx.emit('update:modelValue', val)
      }
    })
    const timeFormat = computed(() => {
      const hasTimeFormat = props.format?.toLowerCase().includes('h') ?? false
      if (hasTimeFormat) {
        return getTimeFormat(props.format)
      } else {
        throw new Error('[MazPicker]: Time format must be indicated or set "no-timer" option')
      }
    })
    const isTwelveFormat = computed(() => timeFormat.value.includes('h'))
    const hours = computed(() => {
      const twoDigit = timeFormat.value?.toLowerCase().includes('hh') ?? false
      const isAfternoon = apm.value ? apm.value === 'pm' || apm.value === 'PM' : false
      const minH = isTwelveFormat.value ? 1 : 0
      const maxH = isTwelveFormat.value ? 12 : 23
      return ArrayHourRange(
        minH,
        maxH,
        twoDigit,
        isAfternoon,
        disabledHoursLocal.value
      )
    })

    const minutes = computed(() => {
      const twoDigit = timeFormat.value?.toLowerCase().includes('mm') ?? false
      return ArrayMinuteRange(
        0,
        60,
        twoDigit,
        props.minuteInterval,
        disabledMinutes.value
      )
    })
    const apms = computed(() => {
      if (!timeFormat.value.includes('A') && !timeFormat.value.includes('a')) return null
      const upper = [{ value: 'AM', item: 'AM' }, { value: 'PM', item: 'PM' }]
      const lower = [{ value: 'am', item: 'am' }, { value: 'pm', item: 'pm' }]
      return isTwelveFormat.value
        ? timeFormat.value.includes('A') ? upper : lower
        : null
    })
    const columns = computed(() => {
      return [
        { type: 'hours', items: hours.value },
        { type: 'minutes', items: minutes.value },
        ...(apms.value ? [{ type: 'apms', items: apms.value }] : [])
      ]
    })
    const isMinDate = computed(() => dateMoment.value ? dateMoment.value.isSame(props.minDate, 'day') : false)
    const isMaxDate = computed(() => dateMoment.value ? dateMoment.value.isSame(props.maxDate, 'day') : false)
    const isMinHour = computed(() => dateMoment.value.isSame(props.minDate, 'hour'))
    const isMaxHour = computed(() => dateMoment.value.isSame(props.maxDate, 'hour'))
    const disabledMinutes = computed(() => {
      if (isMinDate.value && isMinHour.value) {
        // get min limit of minDate
        const minMinute = parseInt(moment(props.minDate, props.format).format('m'), 10)
        return Array.from({ length: minMinute }, (x, i) => i)
      } else if (isMaxDate.value && isMaxHour.value) {
        // get min limit of maxDate
        const maxMinute = parseInt(moment(props.maxDate, props.format).format('m'), 10)
        return Array.from({ length: maxMinute }).fill().map((_, i) => 60 - i)
      }
      return []
    })
    const disabledHoursLocal = computed(() => {
      let hoursDisabled
      if (isMinDate.value) {
        const minHour = parseInt(moment(props.minDate, props.format).format('H'), 10)
        hoursDisabled = Array.from({ length: minHour }, (x, i) => i)
      } else if (isMaxDate.value) {
        const maxhour = parseInt(moment(props.maxDate, props.format).format('H'), 10)
        hoursDisabled = Array.from({ length: 24 - maxhour }).fill().map((_, i) => 24 - i)
      }
      return [
        ...(hoursDisabled || []),
        ...props.disabledHours
      ]
    })

    function setTime () {
      if (!dateMoment.value) return
      const _hour = parseInt(dateMoment.value.format('H'), 10)
      // set hour value
      hour.value = isTwelveFormat.value && [0, 12].includes(_hour) ? _hour === 0 ? 12 : 24 : _hour
      // set minute value
      minute.value = parseInt(dateMoment.value.format('m'), 10)
      if (isTwelveFormat.value) apm.value = hour.value > 12 ? apms.value[1].value : apms.value[0].value
    }
    async function validateTime () {
      await nextTick()
      hour.value = isDisabled('hours', hour.value)
        ? getAvailableTime('hours', hour.value)
        : hour.value
      minute.value = isDisabled('minutes', minute.value)
        ? getAvailableTime('minutes', minute.value)
        : minute.value
    }
    function isDisabled (type, value) {
      return type === 'minutes'
        ? disabledMinutes.value.includes(value)
        : disabledHoursLocal.value.includes(value)
    }
    function isActive (type, value) {
      return (
        type === 'hours'
          ? hour.value
          : type === 'minutes'
            ? minute.value
            : apm.value ? apm.value : null
      ) === value
    }

    function getAvailableTime (type, number) {
      const list = [type].value.map((i) => !i.disabled ? i.value : null).filter((i) => i !== null)
      return findNearestNumberInList(list, number)
    }
    const onScrollHours = debounce(async function (scroll) {
      const value = getValue(scroll)
      const _hour = isTwelveFormat.value
        ? (apm.value?.toLowerCase() ?? false) === 'am'
            ? value + 1
            : (value + 1 + 12)
        : value
      hour.value = _hour === 24 && !isTwelveFormat.value ? 23 : _hour
      await emitValue()
      await initPositionView('hours')
    }, 100)
    const onScrollMinutes = debounce(async function (scroll) {
      const value = getValue(scroll)
      const _minute = value * props.minuteInterval
      minute.value = _minute === 60 ? 59 : _minute
      await emitValue()
      await initPositionView('minutes')
    }, 100)
    const onScrollApms = debounce(async function (scroll) {
      const value = getValue(scroll)
      if (apms.value && apms.value[value] && apm.value !== apms.value[value].value) {
        const newHour = apm.value === apms.value[1].value ? hour.value - 12 : hour.value + 12
        hour.value = newHour
      }
      await emitValue()
      await initPositionView('apms')
    }, 100)
    async function selectTime (item, type) {
      if (type === 'hours') {
        hour.value = item
      } else if (type === 'minutes') {
        minute.value = item
      } else if (type === 'apms' && apm.value !== item) {
        const newHour = item === apms.value[1].value ? hour.value + 12 : hour.value - 12
        hour.value = newHour
      }
      await emitValue()
      await initPositionView(type)
    }
    function emitValue () {
      const _hour = isTwelveFormat.value && [12, 24].includes(hour.value) ? hour.value === 24 ? 12 : 0 : hour.value
      const _minute = minute.value || 0
      dateMoment.value = moment(dateMoment.value?.set({
        hour: _hour,
        minute: _minute
      }), props.format)
    }
    function validateFormat () {
      if (isTwelveFormat.value && !apms.value) throw new Error(`MazPicker - Format Error : To have the twelve hours format, the format must have "A" or "a" (Ex : ${props.format} a)`)
    }
    async function buildColumnPad () {
      await nextTick()
      const pad = (document.getElementById(`timePicker-${uniqueId}`)?.clientHeight ?? 150) / 2 - ITEM_HEIGHT / 2
      columnPadding.value = {
        height: `${pad}px`,
        flex: `0 0 ${pad}px`
      }
    }
    async function initPositionView (containers = isTwelveFormat.value ? ['hours', 'minutes', 'apms'] : ['hours', 'minutes']) {
      await nextTick()
      if (!Array.isArray(containers)) containers = [containers]
      noScrollEvent.value = true
      const hasSmoothEffect = true
      containers.forEach(container => {
        if (!document.getElementById(`column-${container}-root`)) return
        const elem = document.getElementById(`column-${container}-root`)
        const timePickerHeight = document.getElementById(`timePicker-${uniqueId}`)?.clientHeight ?? null
        scrollSmoothElement(elem, timePickerHeight, hasSmoothEffect, ITEM_HEIGHT)
      })
      setTimeout(() => {
        noScrollEvent.value = false
      }, 300)
    }

    watch(() => [disabledMinutes.value, disabledHoursLocal.value].join(), async () => {
      await emitValue()
    })
    watch(() => props.modelValue, async (nVal) => {
      if (!nVal) return
      await setTime()
      await validateTime()
      await emitValue()
      await initPositionView()
    }, {
      immediate: true
    })
    watch(() => props.format, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        validateFormat()
      }
    }, {
      immediate: true
    })
    watch(() => props.height, async (newValue, oldValue) => {
      if (newValue === oldValue) return
      await buildColumnPad()
      await initPositionView()
    }, {
      immediate: true
    })

    return {
      uniqueId,
      timePickerRef,
      minutesref,
      hoursref,
      apmsref,

      hour,
      minute,
      apm,
      noScrollEvent,
      columnPadding,

      dateMoment,
      timeFormat,
      isTwelveFormat,
      hours,
      minutes,
      apms,
      columns,
      isMinDate,
      isMaxDate,
      isMinHour,
      isMaxHour,
      disabledMinutes,
      disabledHoursLocal,

      setTime,
      validateTime,
      isDisabled,
      isActive,
      getAvailableTime,
      onScrollHours,
      onScrollMinutes,
      onScrollApms,
      selectTime,
      emitValue,
      validateFormat,
      buildColumnPad,
      initPositionView
    }
  }
})
</script>
