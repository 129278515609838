
import { defineComponent, computed } from 'vue'
import ArrowIcon from '@web/components/maz/MazPicker/ArrowIcon.vue'
import MazBtn from '@web/components/maz/MazBtn.vue'
export default defineComponent({
  name: 'MonthYearSwitcher',
  components: { ArrowIcon, MazBtn },
  props: {
    months: { type: Array, required: true }
  },
  emits: ['change-month', 'open-month-year-selector'],
  setup (props, ctx) {
    const year = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const years = props.months.map((m: any) => m.getYear())
      return Array.from(new Set(years)).join(' - ')
    })
    const isDouble = computed(() => props.months && props.months.length > 1)

    function changeMonth (val) {
      ctx.emit('change-month', val)
    }

    return {
      year,
      isDouble,

      changeMonth
    }
  }
})
