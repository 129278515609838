import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_maz_picker = _resolveComponent("maz-picker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["asDateTimePicker", {
      '-no-placeholder': _ctx.hidePlaceholder,
      '-focus': _ctx.isFocused
    }])
  }, [
    _createVNode(_component_maz_picker, {
      "auto-close": false,
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _ctx.onInput,
      format: _ctx.format,
      formatted: _ctx.formatted,
      placeholder: _ctx.hidePlaceholder ? null : _ctx.placeholder,
      name: _ctx.name,
      class: "asDateTimePicker_input",
      "no-now": "",
      noTime: _ctx.noTime,
      noDate: _ctx.noDate,
      color: "primary",
      inline: _ctx.inline,
      "minute-interval": _ctx.minuteInterval,
      locale: _ctx.locale,
      onIsShown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFocused = true)),
      onIsHidden: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = false)),
      "min-date": _ctx.minDate
    }, null, 8, ["model-value", "onUpdate:modelValue", "format", "formatted", "placeholder", "name", "noTime", "noDate", "inline", "minute-interval", "locale", "min-date"]),
    _createElementVNode("div", {
      class: "asDateTimePicker_error",
      innerHTML: _ctx.error
    }, null, 8, _hoisted_1)
  ], 2))
}