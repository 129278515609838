
import { defineComponent, ref, computed, watch, nextTick } from 'vue'

import moment from 'moment'
import MazBtn from '@web/components/maz/MazBtn.vue'
import {
  getFormattedValuesIntl,
  getTimeFormat,
  EventBus
} from '@web/components/maz/MazPicker/utils'

export default defineComponent({
  name: 'HeaderPicker',
  components: { MazBtn },
  props: {
    modelValue: { type: Object, default: () => ({}) },
    locale: { type: String, required: true },
    hasTime: { type: Boolean, required: true },
    hasDate: { type: Boolean, required: true },
    format: { type: String, required: true },
    color: { type: String, required: true }
  },
  emits: ['update:modelValue', 'close'],
  setup (props, ctx) {
    const transitionName = ref('maz-slidevnext')
    const currentDate = ref(props.modelValue)

    const isRangeMode = computed(() => !!props.modelValue && Object.keys(props.modelValue).includes('start'))
    const currentValue = computed(() => {
      if (isRangeMode.value) {
        return props.modelValue.end || moment()
      }
      return props.modelValue || moment()
    })
    const year = computed(() => currentValue.value.year())
    const dateFormatted = computed(() => {
      const dates: Date[] = []
      if (isRangeMode.value) {
        dates.push(props.modelValue.start)
        dates.push(props.modelValue.end)
      } else {
        dates.push(props.modelValue as Date)
      }
      return getFormattedValuesIntl({ locale: props.locale, dates })
    })
    const timeFormatted = computed(() => {
      return !isTwelveFormat.value
        ? {
            hour: props.modelValue?.format('HH') ?? null,
            minute: props.modelValue?.format('mm') ?? null
          }
        : props.modelValue?.format(timeFormat.value) ?? null
    })
    const timeFormat = computed(() => {
      return getTimeFormat(props.format)
    })
    const isTwelveFormat = computed(() => timeFormat.value.includes('A') || timeFormat.value.includes('a'))

    function close (e) {
      EventBus.emit('close', e)
      ctx.emit('close', e)
    }

    watch(() => props.modelValue, () => {
      const newValueIsSmaller = currentDate.value ? currentValue.value.isBefore(currentDate.value) : false
      transitionName.value = newValueIsSmaller ? 'maz-slidevprev' : 'maz-slidevnext'
      nextTick(() => { currentDate.value = currentValue.value })
    }, {
      immediate: true
    })

    return {
      transitionName,
      currentDate,

      isRangeMode,
      currentValue,
      year,
      dateFormatted,
      timeFormatted,
      timeFormat,
      isTwelveFormat,
      close
    }
  }
})
