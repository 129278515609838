<template>
  <div class="week-days-labels">
    <div
      v-for="(days, i) in weekDays"
      :key="i"
      class="week-days-labels__days maz-text-muted"
    >
      {{ days }}
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { getWeekDays } from '@web/components/maz/MazPicker/month'
export default defineComponent({
  name: 'WeekDaysLabes',
  props: {
    locale: { type: String, default: null }
  },
  setup (props) {
    const weekDays = computed(() => getWeekDays(props.locale))

    return {
      weekDays
    }
  }
})
</script>
