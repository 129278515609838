import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  slot: "icon-right",
  class: "maz-picker__arrow maz-flex maz-flex-center",
  tabindex: "-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!
  const _component_maz_input = _resolveComponent("maz-input")!
  const _component_pickers_container = _resolveComponent("pickers-container")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.uniqueId,
    ref: "MazPicker",
    class: _normalizeClass(["maz-base-component maz-picker", [{
      'maz-is-dark': _ctx.dark
    }, `maz-picker--${_ctx.color}`]]),
    onBlurCapture: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closePicker($event, 'blur')))
  }, [
    (!_ctx.inline)
      ? (_openBlock(), _createBlock(_component_maz_input, _mergeProps({
          key: 0,
          id: _ctx.uniqueId,
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
        }, _ctx.$attrs, {
          placeholder: _ctx.placeholder,
          readonly: "",
          color: _ctx.color,
          focus: _ctx.hasPickerOpen,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPicker(true)))
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon-left", { slot: "icon-left" }),
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "arrow", {}, () => [
                _createVNode(_component_ArrowIcon, {
                  orientation: _ctx.hasPickerOpen ? 'up': null
                }, null, 8, ["orientation"])
              ])
            ])
          ]),
          _: 3
        }, 16, ["id", "modelValue", "placeholder", "color", "focus"]))
      : _createCommentVNode("", true),
    (_ctx.hasOverlay)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          tabindex: "-1",
          class: "maz-picker__overlay",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closePicker && _ctx.closePicker(...args)))
        }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: _ctx.pickerTransition }, {
      default: _withCtx(() => [
        (_ctx.hasPickerOpen)
          ? (_openBlock(), _createBlock(_component_pickers_container, {
              key: 0,
              ref: "PickersContainer",
              modelValue: _ctx.dateMoment,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateMoment) = $event)),
              locale: _ctx.locale,
              position: _ctx.calcPosition,
              format: _ctx.format,
              "has-header": _ctx.hasHeader,
              "has-footer": _ctx.hasFooter,
              "has-validate": _ctx.hasValidate,
              "has-double": _ctx.hasDouble,
              "has-keyboard": _ctx.hasKeyboard,
              "has-now": _ctx.hasNow,
              "has-time": _ctx.hasTime,
              "has-date": _ctx.hasDate,
              "is-visible": _ctx.hasPickerOpen,
              "minute-interval": _ctx.minuteInterval,
              "now-translation": _ctx.nowTranslation,
              "min-date": _ctx.minDate,
              "max-date": _ctx.maxDate,
              "no-weekends-days": _ctx.noWeekendsDays,
              "disabled-dates": _ctx.disabledDatesMoment,
              "disabled-weekly": _ctx.disabledWeekly,
              "auto-close": _ctx.autoClose,
              shortcuts: _ctx.shortcuts,
              shortcut: _ctx.shortcut,
              "has-shortcuts": _ctx.hasShortcuts,
              "disabled-hours": _ctx.disabledHours,
              inline: _ctx.inline,
              color: _ctx.color
            }, null, 8, ["modelValue", "locale", "position", "format", "has-header", "has-footer", "has-validate", "has-double", "has-keyboard", "has-now", "has-time", "has-date", "is-visible", "minute-interval", "now-translation", "min-date", "max-date", "no-weekends-days", "disabled-dates", "disabled-weekly", "auto-close", "shortcuts", "shortcut", "has-shortcuts", "disabled-hours", "inline", "color"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"])
  ], 42, _hoisted_1))
}