import { computed, ref, SetupContext } from 'vue'
import Locale from '@web/common/locale'

export interface Props {
  error: string;
  modelValue: string;
  placeholder: string;
  required: boolean;
  type: string;
  name: string;
  inline: boolean;
  minuteInterval: number;
}

export const props = {
  error: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    required: true,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'date'
  },
  name: {
    type: String,
    default: 'datetimepicker'
  },
  inline: {
    type: Boolean,
    default: false
  },
  minuteInterval: {
    type: Number,
    default: 1
  },
  minDate: {
    type: String,
    default: null
  }
}

export const emits = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  'update:modelValue': (payload: string): boolean => true
}

export default function (props: Props, emit: SetupContext<typeof emits>['emit']) {
  function onInput (payload: string) {
    emit('update:modelValue', payload || '')
  }

  const format = computed(() => props.type === 'time' ? 'HH:mm' : 'YYYY-MM-DD')
  const formatted = computed(() => props.type === 'time' ? 'HH:mm' : 'DD MMM YYYY')
  const locale = computed(() => Locale.get())

  const hidePlaceholder = computed(() => !props.placeholder)
  const noTime = computed(() => props.type === 'date')
  const noDate = computed(() => props.type === 'time')
  const isFocused = ref(false)

  return {
    onInput,
    format,
    formatted,
    locale,
    hidePlaceholder,
    noTime,
    noDate,
    isFocused
  }
}
