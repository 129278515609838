
import { defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue'

import moment from 'moment'
import { EventBus } from '@web/components/maz/MazPicker/utils'
import MazBtn from '@web/components/maz/MazBtn.vue'
const SHORTCUT_TYPES = ['day', 'date', '-day', 'isoWeek', '-isoWeek', 'month', '-month', 'quarter', 'year', '-year', 'week', '-week']
/**
 * Component used to show a list of the shortcuts currently available
 * and select one of them.
 * @module component - RangeShortcuts
 * @param {Array} shortcuts
 */
export default defineComponent({
  name: 'RangeShortcuts',
  components: { MazBtn },
  props: {
    modelValue: { type: String, default: null },
    color: { type: String, default: null },
    height: { type: Number, required: true },
    shortcuts: {
      type: Array,
      default: () => [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validator: (val: any[]) => val.every(shortcut => {
        const isValueInteger = Number.isInteger(shortcut.value)
        const isFunction = typeof shortcut.value === 'function'
        return shortcut.key && shortcut.label && (isValueInteger || isFunction ? true : SHORTCUT_TYPES.includes(shortcut.value))
      })
    }
  },
  emits: ['update:modelValue', 'change-range', 'day-selected'],
  setup (props, ctx) {
    const computedTypes = ref({})
    const selectedShortcut = ref(null)

    function init () {
      /**
       * Find the pre-selected shortcut
       */
      if (props.modelValue) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _selectedShortcut = props.shortcuts.find((shortcut: any) => shortcut.key === props.modelValue)
        if (_selectedShortcut) select(_selectedShortcut)
      }
    }

    /**
     * Returns the shortcut values according to the key
     * @function getShortcutByKey
     * @param {string} shortcutKey
     * @returns {Object}
     */
    function getShortcutByKey (shortcutKey) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const shortcut: any = props.shortcuts.find((sc: any) => sc.key === shortcutKey)
      if (!shortcut) return false
      const { value } = shortcut
      /**
       * Case where the value is a specific number of days.
       */
      if (typeof value === 'number') {
        return {
          start: moment().subtract(value, 'd'),
          end: moment(),
          value
        }
      }
      /**
       * Case where the value is a function that is in charge of
       * handling the start & end values
       */
      if (typeof value === 'function') {
        const { start, end } = value()
        if (!start || !end) throw new Error('Missing "start" or "end" values.')
        if (!moment.isMoment(start) || !moment.isMoment(end)) throw new Error('The "start" or "end" values are not moment objects.')
        return {
          start,
          end
        }
      }
      switch (value) {
        case 'year': case 'month': case 'quarter': case 'week': case 'isoWeek': case 'day': case 'date':
          return {
            start: moment().startOf(value),
            end: moment().endOf(value),
            value
          }
        case '-month':
          return {
            start: moment().subtract(1, 'months').startOf('month'),
            end: moment().subtract(1, 'months').endOf('month'),
            value
          }
        case '-year':
          return {
            start: moment().subtract(1, 'years').startOf('year'),
            end: moment().subtract(1, 'years').endOf('year'),
            value
          }
        case '-week':
          return {
            start: moment().subtract(1, 'weeks').startOf('week'),
            end: moment().subtract(1, 'weeks').endOf('week'),
            value
          }
        case '-isoWeek':
          return {
            start: moment().subtract(1, 'weeks').startOf('isoWeek'),
            end: moment().subtract(1, 'weeks').endOf('isoWeek'),
            value
          }
        case '-day':
          return {
            start: moment().subtract(1, 'days').startOf('day'),
            end: moment().subtract(1, 'days').endOf('day'),
            value
          }
      }
    }

    function select (shortcut) {
      selectedShortcut.value = shortcut.key
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { start, end, value } = getShortcutByKey(selectedShortcut.value) as any
      ctx.emit('change-range', { start, end, value })
      /**
       * Calls a callback function (if defined) on shortcut click
       */
      if (shortcut.callback) {
        if (typeof shortcut.callback !== 'function') throw new Error('The callback must be a function.')
        shortcut.callback({
          shortcut,
          start,
          end
        })
      }
    }

    watch(() => props.shortcuts, () => {
      init()
    }, {
      immediate: true
    })

    onMounted(() => {
      EventBus.on('day-selected', () => { selectedShortcut.value = null })
    })

    onBeforeUnmount(() => {
      EventBus.off('day-selected', () => { selectedShortcut.value = null })
    })

    return {
      computedTypes,
      selectedShortcut,

      init,
      getShortcutByKey,
      select
    }
  }
})
