import { EventBus } from '@web/components/maz/MazPicker/utils'
import moment, { Moment } from 'moment'
import { ref, computed, nextTick, watch, onMounted, onBeforeUnmount } from 'vue'

const addListerner = ({ keyPressed }) => {
  if (typeof window === 'undefined') return null
  window.addEventListener('keydown', keyPressed)
}

const removeListerner = ({ keyPressed }) => {
  if (typeof window === 'undefined') return null
  window.removeEventListener('keydown', keyPressed)
}

export default function ({ value, isRangeMode, isDisabled, selectDay, hasKeyboard, inline, isVisible, month, afterCheckMonth }) {
  const keyboardSelectedDay = ref<Moment | null>(null)

  const currentValue = computed<Moment>(() => {
    const _currentValue: Moment = isRangeMode
      ? keyboardSelectedDay.value ||
      value.end ||
      value.start ||
      moment()
      : keyboardSelectedDay.value || value || moment()
    return _currentValue
      ? _currentValue.clone()
      : _currentValue
  })

  function keyPressed (e) {
    /*
      13 : Enter
      27 : Escape
      32 : Space
      35 : Page Down
      36 : Page Up
      37 : Left
      38 : Up
      39 : Right
      40 : Down
      40 : Right
    */
    if (
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.keyCode === 35 ||
      e.keyCode === 36
    ) {
      e.view.event.preventDefault()
    }
    try {
      if (e.keyCode === 38) {
        previousWeek()
      } else if (e.keyCode === 37) {
        previousDay()
      } else if (e.keyCode === 39) {
        nextDay()
      } else if (e.keyCode === 40) {
        nextWeek()
      } else if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault()
        selectDay(keyboardSelectedDay.value)
      } else if (e.keyCode === 36) {
        previousMonth()
      } else if (e.keyCode === 35) {
        nextMonth()
      } else if (e.keyCode === 27) {
        EventBus.emit('close', e)
      }
      // if ('activeElement' in document) document.activeElement.blur()
    } catch (err) {
      throw new Error('An error occured while switch date ' + err)
    }
  }

  function previousWeek () {
    const _keyboardSelectedDay = currentValue.value.subtract(1, 'week')
    if (!isDisabled(_keyboardSelectedDay)) {
      keyboardSelectedDay.value = _keyboardSelectedDay
      checkMonth()
    }
  }

  function previousDay () {
    const _keyboardSelectedDay = currentValue.value.subtract(1, 'days')
    if (!isDisabled(_keyboardSelectedDay)) {
      keyboardSelectedDay.value = _keyboardSelectedDay
      checkMonth()
    }
  }

  function nextDay () {
    const _keyboardSelectedDay = currentValue.value.add(1, 'days')
    if (!isDisabled(_keyboardSelectedDay)) {
      keyboardSelectedDay.value = _keyboardSelectedDay
      checkMonth()
    }
  }

  function nextWeek () {
    const _keyboardSelectedDay = currentValue.value.add(1, 'week')
    if (!isDisabled(_keyboardSelectedDay)) {
      keyboardSelectedDay.value = _keyboardSelectedDay
      checkMonth()
    }
  }

  function previousMonth () {
    const _keyboardSelectedDay = currentValue.value.subtract(1, 'month')
    if (!isDisabled(_keyboardSelectedDay)) {
      keyboardSelectedDay.value = _keyboardSelectedDay
      checkMonth()
    }
  }

  function nextMonth () {
    const _keyboardSelectedDay = currentValue.value.add(1, 'month')
    if (!isDisabled(_keyboardSelectedDay)) {
      keyboardSelectedDay.value = _keyboardSelectedDay
      checkMonth()
    }
  }

  function checkMonth () {
    nextTick(() => {
      const newYear = parseInt(currentValue.value.format('YYYY'))
      const currentYear = month.year
      // const currentYear = parseInt(Time().format('YYYY'))
      // const currentMonth = parseInt(Time().format('MM')) - 1
      const isSameYear = newYear === currentYear
      if (
        parseInt(currentValue.value.format('MM')) - 1 !== month.month &&
        isSameYear
      ) {
        if (parseInt(currentValue.value.format('MM')) - 1 > month.month) {
          afterCheckMonth('next')
        } else {
          afterCheckMonth('prev')
        }
      } else if (!isSameYear) {
        if (newYear > currentYear) {
          afterCheckMonth('next')
        } else {
          afterCheckMonth('prev')
        }
      }
    })
  }

  onMounted(() => {
    if (hasKeyboard && (inline || isVisible)) {
      addListerner({ keyPressed })
    }
  })

  onBeforeUnmount(() => {
    removeListerner({ keyPressed })
  })

  watch(isVisible, (nVal) => {
    if (hasKeyboard && nVal) {
      addListerner({ keyPressed })
    } else {
      removeListerner({ keyPressed })
    }
  })

  return {
    keyboardSelectedDay,
    currentValue,

    keyPressed,
    previousWeek,
    previousDay,
    nextDay,
    nextWeek,
    previousMonth,
    nextMonth,
    checkMonth
  }
}
