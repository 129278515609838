
import { defineComponent, computed } from 'vue'

import HeaderPicker from './HeaderPicker.vue'
import Calendar from './Calendar/index.vue'
import FooterPicker from './FooterPicker.vue'
export default defineComponent({
  name: 'PickersContainer',
  components: {
    HeaderPicker,
    Calendar,
    FooterPicker
  },
  props: {
    modelValue: { type: Object, default: null },
    format: { type: String, default: null },
    shortcut: { type: String, default: null },
    locale: { type: String, default: null },
    position: { type: String, required: true },
    color: { type: String, required: true },
    hasHeader: { type: Boolean, required: true },
    hasFooter: { type: Boolean, required: true },
    hasValidate: { type: Boolean, required: true },
    hasNow: { type: Boolean, required: true },
    nowTranslation: { type: String, required: true },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    noWeekendsDays: { type: Boolean, default: false },
    autoClose: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    isVisible: { type: Boolean, default: false },
    disabledDates: { type: Array, required: true },
    disabledWeekly: { type: Array, required: true },
    hasDouble: { type: Boolean, required: true },
    hasKeyboard: { type: Boolean, required: true },
    hasTime: { type: Boolean, required: true },
    hasDate: { type: Boolean, required: true },
    shortcuts: { type: Array, default: null },
    hasShortcuts: { type: Boolean, required: true },
    minuteInterval: { type: Number, required: true },
    disabledHours: { type: Array, required: true }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    const dateMoment = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        ctx.emit('update:modelValue', value)
      }
    })

    return {
      dateMoment
    }
  }
})
