<template>
  <div
    :id="`Calendar${uniqueId}`"
    ref="Calendar"
    class="calendar maz-position-relative maz-mw-100 maz-overflow-hidden maz-flex"
    :class="{
      'is-range': isRangeMode
    }"
  >
    <RangeShortcuts
      v-if="hasShortcuts"
      ref="RangeShortcuts"
      :shortcuts="shortcuts"
      :model-value="shortcut"
      :color="color"
      :height="contentHeight"
      @change-range="$emit('update:modelValue', $event)"
    />
    <div
      v-if="hasDate"
      ref="MonthsContainer"
      class="calendar__months-container maz-overflow-hidden maz-flex-1"
    >
      <MonthYearSwitcher
        :months="months"
        class="maz-px-2"
        @change-month="changeMonth"
        @open-month-year-selector="yearMonthSelectorMode = $event"
      />
      <div class="maz-flex maz-overflow-x-auto">
        <div
          v-for="(month, i) in months"
          :key="`month-${i}`"
          class="calendar__months maz-flex-1"
          style="min-width: 268px;"
          :class="{ 'has-double maz-border-top maz-border-top-solid maz-border-color': hasDouble }"
        >
          <WeekDaysLabels
            :locale="locale"
            class="maz-p-2"
          />
          <MonthPicker
            ref="MonthPicker"
            v-model="dateMoment"
            :month="month"
            :format="format"
            :min-date="minDate"
            :max-date="maxDate"
            :has-keyboard="hasKeyboard"
            :has-double="hasDouble"
            :no-weekends-days="noWeekendsDays"
            :disabled-dates="disabledDates"
            :disabled-weekly="disabledWeekly"
            :color="color"
            :hoverred-day="hoverredDay"
            :is-visible="isVisible"
            class="maz-p-2"
            @change-month="changeMonth"
            @hoverred-day="hoverredDay = $event"
          />
        </div>
      </div>
      <YearMonthSelector
        v-if="months.length"
        v-model="yearMonthSelectorMode"
        :month="months[0]"
        :color="color"
        :has-double="hasDouble"
        @change-month-year="changeMonthYear"
      />
    </div>
    <TimePicker
      v-if="hasTime"
      v-model="dateMoment"
      :format="format"
      :height="contentHeight"
      :min-date="minDate"
      :max-date="maxDate"
      :has-date="hasDate"
      :color="color"
      :minute-interval="minuteInterval"
      :disabled-hours="disabledHours"
    />
  </div>
</template>

<script>
import { defineComponent, computed, watch, ref, nextTick } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import WeekDaysLabels from './WeekDaysLabels.vue'
import MonthPicker from './MonthPicker.vue'
import MonthYearSwitcher from './MonthYearSwitcher.vue'
import YearMonthSelector from './YearMonthSelector.vue'
import RangeShortcuts from './RangeShortcuts.vue'
import TimePicker from './TimePicker.vue'
import Month from '@web/components/maz/MazPicker/month'
import moment from 'moment'
const CONTENT_HEIGHT = 275
export default defineComponent({
  name: 'Calendar',
  components: {
    WeekDaysLabels,
    MonthPicker,
    MonthYearSwitcher,
    YearMonthSelector,
    RangeShortcuts,
    TimePicker
  },
  props: {
    modelValue: { type: Object, default: null },
    format: { type: String, default: null },
    shortcut: { type: String, default: null },
    locale: { type: String, default: null },
    color: { type: String, default: null },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    noWeekendsDays: { type: Boolean, default: false },
    disabledDates: { type: Array, required: true },
    disabledWeekly: { type: Array, required: true },
    isVisible: { type: Boolean, default: false },
    hasDouble: { type: Boolean, required: true },
    hasKeyboard: { type: Boolean, required: true },
    shortcuts: { type: Array, default: null },
    hasShortcuts: { type: Boolean, required: true },
    hasTime: { type: Boolean, required: true },
    hasDate: { type: Boolean, required: true },
    minuteInterval: { type: Number, required: true },
    disabledHours: { type: Array, required: true }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    const uniqueId = uuidv4()

    const MonthsContainer = ref<HTMLElement | null>(null)
    const months = ref([])
    const yearMonthSelectorMode = ref(null)
    const hoverredDay = ref(null)
    const contentHeight = ref(CONTENT_HEIGHT)

    const dateMoment = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        ctx.emit('update:modelValue', value)
      }
    })
    const isRangeMode = computed(() => !!props.modelValue && Object.keys(props.modelValue).includes('start'))
    const currentValue = computed(() => {
      if (isRangeMode.value) {
        return props.modelValue.end || props.modelValue.start || moment()
      }
      return props.modelValue || moment()
    })

    function updateMonth () {
      const currentYear = currentValue.value.year()
      const currentMonth = currentValue.value.month()
      const hasRangeValuesOnDifferentsMonths = props.modelValue && props.modelValue.start && props.modelValue.end && props.modelValue.start.month() !== props.modelValue.end.month()
      months.value = getMonths({
        year: currentYear,
        month: hasRangeValuesOnDifferentsMonths ? currentMonth - 1 : currentMonth
      })
    }
    function monthsAreDifferent (newValue, oldValue) {
      if (!newValue || !oldValue) return false
      return newValue.month() !== oldValue.month()
    }
    function valueIsInMonths (newMonth) {
      return months.value.some(m => m.month === newMonth)
    }
    function isDifferentYear (newCurrentValue, oldCurrentValue) {
      if (!newCurrentValue || !oldCurrentValue) return false
      return newCurrentValue.year() !== oldCurrentValue.year()
    }
    function changeMonth (val) {
      let month = months.value[0].month + (val === 'prev' ? -1 : +1)
      let year = months.value[0].year
      if (month > 11 || month < 0) {
        year += (val === 'prev' ? -1 : +1)
        month = (val === 'prev' ? 11 : 0)
      }
      months.value = getMonths({ year, month })
    }
    function changeMonthYear (payload) {
      months.value = getMonths(payload)
    }
    function getMonths ({ month, year }) {
      const numberOfMonths = Array.from(Array(props.hasDouble ? 2 : 1).keys())
      return numberOfMonths.map((i) => {
        const newMonthNumber = month + i
        const monthNumber = newMonthNumber === 12 ? 0 : newMonthNumber
        const yearNumber = newMonthNumber === 12 ? year + 1 : year
        return new Month(monthNumber, yearNumber, props.locale)
      })
    }

    watch(() => props.modelValue, (newValue, oldValue) => {
      const newCurrentValue = isRangeMode.value && newValue
        ? newValue.end || newValue.start
        : newValue
      const oldCurrentValue = isRangeMode.value && oldValue
        ? oldValue.end || oldValue.start
        : oldValue
      if (
        !months.value.length || isDifferentYear(newCurrentValue, oldCurrentValue) ||
        (monthsAreDifferent(newCurrentValue, oldCurrentValue) && !valueIsInMonths(newCurrentValue.month()))
      ) {
        updateMonth()
      }
    }, {
      immediate: true
    })
    watch(months, async () => {
      contentHeight.value = CONTENT_HEIGHT
      await nextTick()
      contentHeight.value = MonthsContainer.value && MonthsContainer.value.clientHeight ? MonthsContainer.value.clientHeight : CONTENT_HEIGHT
    }, {
      immediate: true
    })
    watch(() => props.locale, async () => {
      updateMonth()
    }, {
      immediate: true
    })

    return {
      uniqueId,

      MonthsContainer,

      months,
      yearMonthSelectorMode,
      hoverredDay,
      contentHeight,

      dateMoment,
      isRangeMode,
      currentValue,

      updateMonth,
      monthsAreDifferent,
      valueIsInMonths,
      isDifferentYear,
      changeMonth,
      changeMonthYear,
      getMonths
    }
  }
})
</script>
