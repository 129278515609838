
import { defineComponent, computed } from 'vue'

import { EventBus } from '@web/components/maz/MazPicker/utils'
import MazBtn from '@web/components/maz/MazBtn.vue'
export default defineComponent({
  name: 'FooterPicker',
  components: { MazBtn },
  props: {
    modelValue: { type: Object, default: null },
    hasValidate: { type: Boolean, required: true },
    hasNow: { type: Boolean, required: true },
    nowTranslation: { type: String, required: true },
    color: { type: String, required: true }
  },
  emits: ['validate', 'now'],
  setup (props, ctx) {
    const isRangeMode = computed(() => !!props.modelValue && Object.keys(props.modelValue).includes('start'))
    const currentValue = computed(() => {
      if (isRangeMode.value) {
        return props.modelValue.end
      }
      return props.modelValue
    })

    function validate (e) {
      EventBus.emit('validate', e)
      ctx.emit('validate', e)
    }

    function now (e) {
      EventBus.emit('now', e)
      ctx.emit('now', e)
    }

    return {
      isRangeMode,
      currentValue,
      validate,
      now
    }
  }
})
