
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ArrowIcon',
  props: {
    dark: { type: Boolean, default: false },
    color: { type: String, default: null },
    size: { type: Number, default: 24 },
    orientation: { type: String, default: 'bottom' }
  }
})
