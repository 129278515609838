
import { defineComponent } from 'vue'

import MazPicker from '@web/components/maz/MazPicker/index.vue'

import useDateTimePicker, { props, emits } from '@web/composition/useDateTimePicker'

export default defineComponent({
  components: {
    MazPicker
  },
  props,
  emits,
  setup (props, ctx) {
    return {
      ...useDateTimePicker(props, ctx.emit)
    }
  }
})
