<template>
  <transition-group
    :name="transitionDaysName"
  >
    <div
      key="month-picker"
      class="month-picker maz-position-relative"
      :class="{ 'month-picker--long': (monthDays.length + weekStart) > 35 }"
    >
      <div
        v-for="m in [month]"
        :key="m.month"
        class="month-picker__days"
      >
        <div
          v-for="(w, i) in Array.from(Array(weekStart).keys())"
          :key="`previous-${i}`"
        />
        <MazBtn
          v-for="(day, i) in allDays"
          :key="i"
          class="month-picker__day maz-text-color maz-bg-transparent maz-flex maz-flex-center"
          size="mini"
          :color="color"
          tabindex="-1"
          :no-shadow="!isSelectedDate(day)"
          :disabled="isDisabled(day)"
          :active="isSelectedDate(day)"
          :class="{
          'highlight': isToday(day),
          'is-keyboard-selected': isKeyboardSelected(day),
          'is-in-range': !isDisabled(day) && isBetween(day),
          'is-between-hoverred': modelValue && modelValue.start && !isDisabled(day) && isBetweenHoverred(day),
          'is-first-in-range': isFirstInRange(day),
          'is-last-in-range': isLastInRange(day)
        }"
          @mouseenter="$emit('hoverred-day', day)"
          @mouseleave="$emit('hoverred-day', null)"
          @click="selectDay(day)"
        >
          {{ day.format('D') }}
        </MazBtn>
      </div>
    </div>
  </transition-group>
</template>

<script>
import { defineComponent, ref, computed, nextTick, watch, onMounted } from 'vue'
import useKeyboardAccessibility from '../../keyboard-accessibility'
import MazBtn from '@web/components/maz/MazBtn'
import { EventBus } from '@web/components/maz/MazPicker/utils'
import moment from 'moment'
export default defineComponent({
  name: 'MonthPicker',
  components: { MazBtn },
  props: {
    modelValue: { type: Object, default: () => ({}) },
    month: { type: Object, required: true },
    color: { type: String, required: true },
    format: { type: String, default: null },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    noWeekendsDays: { type: Boolean, default: false },
    disabledDates: { type: Array, required: true },
    disabledWeekly: { type: Array, required: true },
    isVisible: { type: Boolean, required: true },
    hasDouble: { type: Boolean, required: true },
    hoverredDay: { type: Object, default: null },
    hasKeyboard: { type: Boolean, default: true }
  },
  emits: ['update:modelValue', 'day-selected', 'hoverred-day', 'change-month'],
  setup (props, ctx) {
    const transitionDaysName = ref('maz-slidenext')
    const currentMonth = ref(props.month)

    const dateMoment = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        const valueToEmit = isRangeMode.value
          ? value
          : value.set({
            hour: props.modelValue?.hour() ?? 0,
            minute: props.modelValue?.minute() ?? 0
          })
        ctx.emit('update:modelValue', valueToEmit)
      }
    })
    const allDays = computed(() => monthDays.value)
    const monthDays = computed(() => props.month.getMonthDays())
    const weekStart = computed(() => props.month.getWeekStart())
    const isRangeMode = computed(() => !!dateMoment.value && Object.keys(dateMoment.value).includes('start'))
    const minDateDay = computed(() => props.minDate ? moment(props.minDate, props.format).startOf('day') : null)
    const maxDateDay = computed(() => props.maxDate ? moment(props.maxDate, props.format).endOf('day') : null)

    function isToday (day) {
      return day.isSame(new Date(), 'day')
    }
    function isBetweenHoverred (day) {
      if (!isRangeMode.value || dateMoment.value.end) return false
      return day.isBetween(dateMoment.value.start, props.hoverredDay, null, '[]')
    }
    function isBetween (day) {
      if (!isRangeMode.value) return false
      return day.isBetween(dateMoment.value.start, dateMoment.value.end, null, '[]')
    }
    function isFirstInRange (day) {
      if (!isRangeMode.value) return false
      return day.isSame(dateMoment.value.start, 'day')
    }
    function isLastInRange (day) {
      if (!isRangeMode.value) return false
      return day.isSame(dateMoment.value.end, 'day')
    }
    function isSelectedDate (day) {
      return isRangeMode.value
        ? (dateMoment.value?.start?.isSame(day, 'day') ?? false) || (dateMoment.value?.end?.isSame(day, 'day') ?? false)
        : dateMoment.value ? dateMoment.value.isSame(day, 'day') : false
    }
    function isDisabled (day) {
      return day.startOf('day').isBefore(minDateDay.value) ||
        day.startOf('day').isAfter(maxDateDay.value) ||
        (props.noWeekendsDays && isWeekEndDay(day)) ||
        isDateDisabled(day) ||
        isDayDisabledWeekly(day)
    }
    function isWeekEndDay (day) {
      const dayConst = day.day()
      const weekendsDaysNumbers = [6, 0]
      return props.noWeekendsDays ? weekendsDaysNumbers.indexOf(dayConst) > -1 : false
    }
    function isDateDisabled (day) {
      return props.disabledDates.some(d => d.isSame(day, 'day'))
    }
    function isDayDisabledWeekly (day) {
      const dayConst = day.day()
      return props.disabledWeekly.includes(dayConst)
    }
    function isKeyboardSelected (day) {
      return day.isSame(keyboardSelectedDay.value, 'day')
    }
    function selectDay (day) {
      EventBus.emit('day-selected')
      ctx.emit('day-selected')
      let valueToSend = day
      if (isRangeMode.value) {
        const { start, end } = dateMoment.value
        if (!start || (start && end) || day.isBefore(dateMoment.value.start)) {
          valueToSend = {
            start: day,
            end: null
          }
        } else {
          valueToSend = {
            start: dateMoment.value.start,
            end: day
          }
        }
      }
      dateMoment.value = valueToSend
    }

    const {
      keyboardSelectedDay,
      ...data
    } = useKeyboardAccessibility({
      value: props.modelValue,
      isRangeMode,
      isDisabled,
      selectDay,
      hasKeyboard: props.hasKeyboard,
      isVisible: props.isVisible,
      month: props.month,
      afterCheckMonth: type => ctx.emit('change-month', type)
    })

    watch(() => props.month, (nVal) => {
      const newValueIsSmaller = currentMonth.value.start > nVal.start
      transitionDaysName.value = newValueIsSmaller ? 'maz-slideprev' : 'maz-slidenext'
      nextTick(() => { currentMonth.value = nVal })
    })

    onMounted(() => {
      if (props.noWeekendsDays && isWeekEndDay(dateMoment.value)) {
        throw new Error('[MazPicker]: the value provide is a weekend day and you use the option \'no-weekends-days\'')
      }
      if (isDateDisabled(dateMoment.value)) {
        throw new Error('[MazPicker]: the value provide is a disabled date by the option \'disabled-dates\'')
      }
    })

    return {
      ...data,
      keyboardSelectedDay,

      transitionDaysName,
      currentMonth,

      dateMoment,
      allDays,
      monthDays,
      weekStart,
      isRangeMode,
      minDateDay,
      maxDateDay,

      isToday,
      isBetweenHoverred,
      isBetween,
      isFirstInRange,
      isLastInRange,
      isSelectedDate,
      isDisabled,
      isWeekEndDay,
      isDateDisabled,
      isDayDisabledWeekly,
      isKeyboardSelected,
      selectDay
    }
  }
})
</script>
