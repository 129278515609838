import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "month-year-switcher maz-flex maz-space-between maz-align-center maz-py-2" }
const _hoisted_2 = { class: "maz-flex-1 maz-flex maz-flex-center" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!
  const _component_MazBtn = _resolveComponent("MazBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MazBtn, {
      fab: "",
      "no-shadow": "",
      color: "grey",
      size: "mini",
      class: "month-year-switcher__previous maz-flex maz-flex-center maz-bg-transparent maz-hover-bg-color maz-no-focus-bg",
      tabindex: "-1",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeMonth('prev')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowIcon, { orientation: "left" })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MazBtn, {
        "no-shadow": "",
        tabindex: "-1",
        color: "grey",
        class: "maz-text-color maz-bg-transparent maz-hover-bg-color maz-no-focus-bg maz-p-2 maz-mr-1",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-month-year-selector', 'month')))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.months, (m, i) => {
            return (_openBlock(), _createElementBlock("span", { key: i }, [
              _createTextVNode(_toDisplayString(m.getFormatted()) + " ", 1),
              (_ctx.months.length > 1 && i === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, " -  "))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_MazBtn, {
        tabindex: "-1",
        "no-shadow": "",
        color: "grey",
        class: "maz-text-color maz-bg-transparent maz-hover-bg-color maz-no-focus-bg maz-p-2",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('open-month-year-selector', 'year')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.year), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_MazBtn, {
      fab: "",
      "no-shadow": "",
      color: "grey",
      size: "mini",
      tabindex: "-1",
      class: "maz-flex maz-flex-center maz-bg-transparent maz-hover-bg-color maz-no-focus-bg",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeMonth('next')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowIcon, { orientation: "right" })
      ]),
      _: 1
    })
  ]))
}