
import { defineComponent, ref, computed, nextTick, watch, onMounted, onBeforeUnmount } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import PickersContainer from '@web/components/maz/MazPicker/PickersContainer/index.vue'
import ArrowIcon from '@web/components/maz/MazPicker/ArrowIcon.vue'
import MazInput from '@web/components/maz/MazInput.vue'

import moment from 'moment'

import {
  capitalize,
  getDefaultLocale,
  checkIfTargetIsAllowedToCloseComponent,
  hasDateBetweenMinMaxDate,
  forceUpdateComputedData,
  getDateMoment,
  getFormattedValue,
  EventBus
} from '@web/components/maz/MazPicker/utils'

const NOT_ALLOWED_CLASSES_TO_CLOSE = [
  ['year-month-selector__btn'],
  ['year-month-selector__close']
]
const DOUBLE_PICKER_HEIGHT = 435
const PICKER_HEIGHT = 386
const HEADER_HEIGHT = 57
const FOOTER_HEIGHT = 54
/**
 * > Date, Time & Range Picker
 */
export default defineComponent({
  name: 'MazPicker',
  components: {
    PickersContainer,
    ArrowIcon,
    MazInput
  },
  props: {
    // v-model --> input value
    // must be is the same format like
    modelValue: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validator: (prop: any) => ['string', 'object'].includes(typeof prop) || prop === null,
      default: null
    },
    // if is `true`, the picker is open
    open: { type: Boolean, default: false },
    // moment JS locale
    locale: {
      validator: (prop: string | null) => ['string'].includes(typeof prop) || prop === null,
      default: getDefaultLocale()
    },
    // override the date picker postion (top / bottom / left / right)
    position: { type: String, default: null },
    // the value in `v-model` will be returned in this format
    format: { type: String, default: 'YYYY-MM-DD h:mm a' },
    // the value in `@formatted` event & shown in input will be formatted with this (formats availables on [MomentJS](https://momentjs.com/))
    formatted: { type: String, default: 'llll' },
    // minimum date the user can set (same format as the model)
    minDate: { type: String, default: null },
    // maximum date the user can set (same format as the model)
    maxDate: { type: String, default: null },
    // set dark mode
    dark: { type: Boolean, default: false },
    // Date picker is always open
    persistent: { type: Boolean, default: false },
    // to remove the picker's header
    noHeader: { type: Boolean, default: false },
    // to remove the picker's footer (buttons container)
    noFooter: { type: Boolean, default: false },
    // to remove the `now` button
    noNow: { type: Boolean, default: false },
    // translation of now of button
    nowTranslation: { type: String, default: 'Now' },
    // all week-ends days disabled
    noWeekendsDays: { type: Boolean, default: false },
    // close picker on select date
    autoClose: { type: Boolean, default: false },
    // Inline picker UI (no input, no dialog)
    inline: { type: Boolean, default: false },
    // disabled dates `Array of dates (same format as the value/format attribute)`,
    disabledDates: { type: Array, default: () => [] },
    // Days of the week which are disabled every week, in Array format with day index, Sunday as 0 and Saturday as 6: `[0,4,6]`
    disabledWeekly: { type: Array, default: Array },
    // show double calendar
    double: { type: Boolean, default: false },
    // Enable range mode to select periode
    range: { type: Boolean, default: false },
    // Change placeholder/label of input
    placeholder: { type: String, default: 'Select date time' },
    // Disabled keyboard accessibility & navigation
    noKeyboard: { type: Boolean, default: false },
    // Disabled time picker
    noTime: { type: Boolean, default: false },
    // Disabled date picker
    noDate: { type: Boolean, default: false },
    // Change minute interval in time picker
    minuteInterval: { type: Number, default: 1 },
    // Must be an Array of integer: `0` to `24` (0 = 12am, 24 = 12pm) => `[0,1,2,3,4,5,6,7,19,20,21,22,23]`
    disabledHours: { type: Array, default: Array },
    // Disable the overlay on mobile
    noOverlay: { type: Boolean, default: false },
    // pre selected shortcut: provide a shortcut key
    shortcut: { type: String, default: null },
    // Disabled shortcuts in range mode
    noShortcuts: { type: Boolean, default: false },
    // shortcuts for range mode
    shortcuts: {
      type: Array,
      default: () => ([
        { key: 'thisWeek', label: 'This week', value: 'isoWeek' },
        { key: 'lastWeek', label: 'Last week', value: '-isoWeek' },
        { key: 'last7Days', label: 'Last 7 days', value: 7 },
        { key: 'last30Days', label: 'Last 30 days', value: 30 },
        { key: 'thisMonth', label: 'This month', value: 'month' },
        { key: 'lastMonth', label: 'Last month', value: '-month' },
        { key: 'thisYear', label: 'This year', value: 'year' },
        { key: 'lastYear', label: 'Last year', value: '-year' }
      ])
    },
    // choose main color
    color: { type: String, default: 'primary' }
  },
  emits: ['update:modelValue', 'formatted', 'is-shown', 'is-hidden', 'destroy', 'now', 'validate'],
  setup (props, ctx) {
    const uniqueId = uuidv4()

    const MazPicker = ref<HTMLInputElement | null>(null)
    const PickersContainer = ref<HTMLInputElement | null>(null)

    const isOpen = ref<boolean>(false)
    const calcPosition = ref('bottom left')
    const update = ref(false)

    const inputValue = computed({
      get () {
        forceUpdateComputedData()
        return capitalize(getFormattedValue(props.modelValue, props.format, props.formatted, props.range))
      },
      set () {
        emitValue(null)
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateMoment = computed<any>({
      get () {
        forceUpdateComputedData()
        return getDateMoment(props.modelValue, props.format, props.range)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set (value: any) {
        emitValue(value)
      }
    })
    const minDateDay = computed<moment.Moment | null>(() => props.minDate ? moment(props.minDate, props.format).startOf('day') : null)
    const maxDateDay = computed<moment.Moment | null>(() => props.maxDate ? moment(props.maxDate, props.format).endOf('day') : null)
    const hasPickerOpen = computed(() => isOpen.value || props.open || props.inline)
    const pickerTransition = computed(() => calcPosition.value.includes('bottom') ? 'maz-slide' : 'maz-slideinvert')
    const hasHeader = computed(() => !props.noHeader)
    const hasFooter = computed(() => !props.noFooter && (hasValidate.value || hasNow.value))
    const hasValidate = computed(() => !props.inline && !props.autoClose)
    const hasNow = computed(() => !props.noNow && !props.range)
    const hasKeyboard = computed(() => !props.noKeyboard && !hasDouble.value)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const disabledDatesMoment = computed(() => props.disabledDates.map((d: any) => moment(d, props.format)))
    const hasDouble = computed(() => props.double)
    const hasTime = computed(() => !props.noTime && !props.range)
    const hasDate = computed(() => !props.noDate)
    const hasShortcuts = computed(() => !props.noShortcuts && props.range)
    const hasOverlay = computed(() => !props.noOverlay && hasPickerOpen.value && !props.inline)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function emitValue (val: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let valueToSend: any
      if (props.range) {
        if (val) {
          const { start, end } = val as { start: moment.Moment | null; end: moment.Moment | null }
          valueToSend = {
            start: start instanceof moment ? start.format(props.format) : null,
            end: end instanceof moment ? end.format(props.format) : null
          }
        } else {
          valueToSend = null
        }
      } else {
        valueToSend = val instanceof moment ? (val as moment.Moment).format(props.format) : null
      }
      const sameHaseCurrentValue = valueToSend === props.modelValue
      if (sameHaseCurrentValue) return
      if (props.autoClose && !props.range) closePicker()
      if (props.autoClose && props.range && val?.start && val?.end) closePicker()
      // return the date value (in `@input` or `v-model`)
      // @arg date formatted with "format" option
      ctx.emit('update:modelValue', valueToSend)
    }
    function emitFormatted (value) {
      if (props.modelValue) {
        // return the date value (in `@formatted` event)
        // @arg date formatted with "formatted" option
        ctx.emit('formatted', getFormattedValue(value, props.format, props.formatted, props.range))
      }
    }
    function openPicker () {
      isOpen.value = true
      // emit when picker is show
      ctx.emit('is-shown')
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function closePicker (e: any = {}) {
      if (
        MazPicker.value?.contains(e.relatedTarget) || checkIfTargetIsAllowedToCloseComponent(NOT_ALLOWED_CLASSES_TO_CLOSE, e.target)
      ) return
      isOpen.value = false
      // emit when picker is hide
      ctx.emit('is-hidden')
    }
    async function getVerticalPosition () {
      if (typeof window === 'undefined') return 'top'
      await nextTick()
      const parentRect = MazPicker.value?.getBoundingClientRect()
      const windowHeight = window.innerHeight
      let datePickerHeight = hasDouble.value ? DOUBLE_PICKER_HEIGHT : PICKER_HEIGHT
      datePickerHeight = props.noFooter ? datePickerHeight - HEADER_HEIGHT : datePickerHeight
      datePickerHeight = props.noHeader ? datePickerHeight - FOOTER_HEIGHT : datePickerHeight
      if ((parentRect?.top || 0) < datePickerHeight) {
        // No place on top --> bottom
        return 'bottom'
      } else if (windowHeight - ((parentRect?.height || 0) + datePickerHeight + (parentRect?.top || 0)) >= 0) {
        // Have place on bottom --> bottom
        return 'bottom'
      } else {
        // No place on bottom --> top
        return 'top'
      }
    }

    onMounted(() => {
      EventBus.on('validate', () => {
        closePicker()
        // emit when the user click on validate button
        ctx.emit('validate')
      })
      EventBus.on('now', () => {
        emitValue(moment())
        // emit when the user click on now button
        ctx.emit('now')
      })
      EventBus.on('close', () => { closePicker() })
    })
    onBeforeUnmount(() => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      EventBus.off('validate', () => {})
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      EventBus.off('now', () => {})
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      EventBus.off('close', () => {})
      // emit on before destroy
      ctx.emit('destroy')
    })

    watch(dateMoment, (nVal) => {
      if (nVal && (minDateDay.value || maxDateDay.value)) {
        if (props.range) return
        const { isBefore, isAfter } = hasDateBetweenMinMaxDate(
          nVal,
          minDateDay.value,
          maxDateDay.value,
          props.range
        )
        if (isAfter) emitValue(maxDateDay.value)
        if (isBefore) emitValue(minDateDay.value)
      }
      emitFormatted(nVal)
    }, {
      immediate: true
    })

    watch(() => props.locale, (nVal) => {
      moment.locale(nVal)
      update.value = !update.value
    }, {
      immediate: true
    })

    watch(hasPickerOpen, async (nVal) => {
      const verticalPosition = await getVerticalPosition()
      if (nVal) calcPosition.value = props.position || `${verticalPosition} left`
    }, {
      immediate: true
    })

    return {
      uniqueId,

      MazPicker,
      PickersContainer,

      isOpen,
      calcPosition,
      update,

      inputValue,
      dateMoment,
      minDateDay,
      maxDateDay,
      hasPickerOpen,
      pickerTransition,
      hasHeader,
      hasFooter,
      hasValidate,
      hasNow,
      hasKeyboard,
      disabledDatesMoment,
      hasDouble,
      hasTime,
      hasDate,
      hasShortcuts,
      hasOverlay,

      emitValue,
      emitFormatted,
      openPicker,
      closePicker
    }
  }
})
