<template>
  <transition name="maz-slide">
    <div
      v-if="isOpen"
      class="year-month-selector maz-p-2 maz-flex maz-direction-column"
    >
      <div class="maz-flex maz-justify-end maz-align-center">
        <div
          v-if="modelValue === 'year'"
          class="maz-flex maz-align-center"
        >
          <MazBtn
            fab
            no-shadow
            size="mini"
            color="grey"
            class="maz-flex maz-flex-center maz-mr-1 maz-bg-transparent maz-hover-bg-color maz-no-focus-bg"
            tabindex="-1"
            @click="updateYears('prev')"
          >
            <ArrowIcon
              orientation="left"
              color="text-grey"
            />
          </MazBtn>
          <MazBtn
            fab
            no-shadow
            size="mini"
            color="grey"
            class="maz-flex maz-flex-center maz-mr-1 maz-bg-transparent maz-hover-bg-color maz-no-focus-bg"
            tabindex="-1"
            @click="updateYears('next')"
          >
            <ArrowIcon
              orientation="right"
              color="text-grey"
            />
          </MazBtn>
        </div>
        <MazBtn
          fab
          no-shadow
          size="mini"
          color="grey"
          tabindex="-1"
          class="year-month-selector__close maz-bg-transparent maz-hover-bg-color maz-no-focus-bg"
          @click="closePanel"
        >
          <i class="material-icons maz-text-color maz-fs-20">
            close
          </i>
        </MazBtn>
      </div>
      <div class="maz-flex-1 maz-flex maz-flex-wrap maz-space-between maz-align-center maz-pt-2">
        <MazBtn
          v-for="(m, i) in months"
          :key="i"
          :color="color"
          :active="currentMonth === i"
          :class="[
            currentMonth !== i ? `maz-hover-bg-color maz-no-focus-bg maz-border maz-border-color maz-text-${color}`: `maz-focus-${color}`,
            { 'mx-3': hasDouble }
          ]"
          class="year-month-selector__btn maz-bg-transparent maz-no-shadow maz-px-3 maz-flex-20 maz-mx-1"
          tabindex="-1"
          @click="selectMonth(i)"
        >
          {{ m }}
        </MazBtn>
        <MazBtn
          v-for="year in years"
          :key="year"
          :color="color"
          :active="currentYear === year"
          size="md"
          tabindex="-1"
          :class="[currentYear !== year ? `maz-hover-bg-color maz-no-focus-bg maz-border maz-border-color maz-text-${color}`: `maz-focus-${color}`]"
          class="year-month-selector__btn maz-bg-transparent maz-no-shadow"
          @click="selectYear(year)"
        >
          {{ year }}
        </MazBtn>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { getMonthsByFormat } from '@web/components/maz/MazPicker/month'
import ArrowIcon from '@web/components/maz/MazPicker/ArrowIcon.vue'
import MazBtn from '@web/components/maz/MazBtn.vue'
const ArrayRange = (start, end) => {
  return Array(end - start + 1).fill().map((_, idx) => {
    const n = start + idx
    return n
  })
}
export default defineComponent({
  name: 'YearMonthSelector',
  components: { ArrowIcon, MazBtn },
  props: {
    modelValue: { type: String, default: null },
    color: { type: String, default: null },
    month: { type: Object, required: true },
    hasDouble: { type: Boolean, required: true }
  },
  emits: ['update:modelValue', 'change-month-year'],
  setup (props, ctx) {
    const years = ref([])
    const months = ref([])

    const isOpen = computed(() => props.modelValue !== null)
    const currentMonth = computed(() => props.month.month)
    const currentYear = computed(() => props.month.year)
    const isMonthMode = computed(() => props.modelValue === 'month')

    function closePanel () {
      ctx.emit('update:modelValue', null)
    }
    function getMonths () {
      years.value = []
      months.value = getMonthsByFormat(props.hasDouble ? 'MMMM' : 'MMM')
    }
    function getYears (offset = props.hasDouble ? 17 : 7) {
      months.value = []
      years.value = ArrayRange(props.month.year - offset, props.month.year + offset)
    }
    function selectMonth (monthNumber) {
      ctx.emit('change-month-year', { month: monthNumber, year: currentYear.value })
      closePanel()
    }
    function selectYear (year) {
      ctx.emit('change-month-year', { month: currentMonth.value, year: year })
      closePanel()
    }
    function updateYears (period) {
      const offset = props.hasDouble ? 17 : 7
      const offsetYears = period === 'next' ? offset : -offset
      years.value = ArrayRange(years.value[0] + offsetYears, years.value[years.value.length - 1] + offsetYears)
    }

    watch(() => props.modelValue, () => {
      isMonthMode.value ? getMonths() : getYears()
    }, {
      immediate: true
    })

    return {
      years,
      months,

      isOpen,
      currentMonth,
      currentYear,
      isMonthMode,

      closePanel,
      getMonths,
      getYears,
      selectMonth,
      selectYear,
      updateYears
    }
  }
})
</script>
