import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "maz-flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MazBtn = _resolveComponent("MazBtn")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([
      {
        height: `${_ctx.height}px`,
        width: `${140}px`,
        minWidth: `${140}px`
      }
    ]),
    class: "range-shortcuts maz-flex maz-direction-column maz-px-2 maz-py-1 maz-border-color maz-border-right maz-border-right-solid maz-overflow-y-auto"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shortcuts, (shortcut) => {
      return (_openBlock(), _createBlock(_component_MazBtn, {
        key: shortcut.key,
        active: _ctx.selectedShortcut === shortcut.key,
        size: "sm",
        color: _ctx.color,
        tabindex: "-1",
        class: _normalizeClass([[_ctx.selectedShortcut !== shortcut.key ? `maz-hover-bg-color maz-no-focus-bg maz-border maz-border-color maz-text-${_ctx.color}`: `maz-focus-${_ctx.color}`], "shortcut-button maz-flex-1 maz-my-1 maz-bg-transparent maz-no-shadow"]),
        onClick: ($event: any) => (_ctx.select(shortcut))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(shortcut.label), 1)
        ]),
        _: 2
      }, 1032, ["active", "color", "class", "onClick"]))
    }), 128))
  ], 4))
}